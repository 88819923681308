import React from "react"
import { Link } from 'gatsby-plugin-modal-routing'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Title from "../components/title"

const Success = () => (
  <Layout>
    <SEO title="Form Accepted" />
    <Title title="Form Accepted" calltoaction="You successfully submitted the form." filename="pen.jpg" />
    <div className="centered-content">
        <Link to="/">Go Home</Link>
    </div>
  </Layout>
);

export default Success;